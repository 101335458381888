<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        :page.sync="page"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.sys_user_delete"
            @click="handleDelete">删 除
        </el-button>
        <el-button
            type="primary"
            size="small"
            plain
            v-if="permission.sys_user_reset"
            icon="el-icon-refresh"
            @click="handleReset">密码重置
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { submit, resetPassword } from '@/api/sys/user'
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    const validateMobile = (rule, value, callback) => {
      if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号格式'))
      } else {
        callback()
      }
    }
    return {
      module: 'sys/user',
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      init: {
        roleTree: [],
        deptTree: []
      },
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        dialogHeight: 450,
        searchMenuSpan: 5,
        column: [
          {
            label: '用户名',
            maxlength: 20,
            prop: 'username',
            search: true,
            rules: [{
              required: true,
              message: '请输入用户名'
            }]
          },

          {
            label: '密码',
            maxlength: 20,
            prop: 'password',
            hide: true,
            viewDisplay: false,
            rules: [{
              required: true,
              message: '请输入密码'
            }]
          },
          {
            label: '真实姓名',
            maxlength: 20,
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入真实姓名'
            }]
          },
          // {
          //   label: '系统',
          //   prop: 'roleIdList',
          //   multiple: true,
          //   type: 'select',
          //   dicUrl: '/sys/role/dict',
          //   rules: [{
          //     required: true,
          //     message: '请选择系统',
          //     trigger: 'click'
          //   }]
          // },
          // {
          //   label: '角色',
          //   prop: 'roleName',
          //   display: false
          // },
          {
            label: '角色',
            prop: 'roleIdList',
            multiple: true,
            type: 'select',
            dicUrl: '/sys/role/dict',
            rules: [{
              required: true,
              message: '请选择系统',
              trigger: 'click'
            }]
          },
          {
            label: '系统',
            prop: 'systemName',
            display: false
          },
          {
            label: '实验中心',
            prop: 'centerIdList',
            multiple: true,
            type: 'select',
            dicUrl: '/sys/center/dict',
            rules: [{
              required: true,
              message: '请选择实验中心',
              trigger: 'click'
            }]
          },
          {
            label: '手机',
            prop: 'mobile',
            isShow: false,
            rules: [{
              required: false,
              validator: validateMobile
            }],
            hide: true
          },
          {
            label: '备注',
            maxlength: 20,
            prop: 'remark',
            // display: false,
            type: 'textarea',
            isShow: false,
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            hide: true
          },
          {
            label: '创建人',
            prop: 'creator',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            type: 'datetime',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 00:00:00'
          }
          // {
          //   label: '所属部门',
          //   prop: 'deptId',
          //   type: 'tree',
          //   dicUrl: '/sys/dept/tree',
          //   dataType: 'string',
          //   props: {
          //     value: 'id',
          //     label: 'name'
          //   },
          //   rules: [{
          //     // required: true,
          //     message: '请选择所属部门',
          //     trigger: 'click'
          //   }]
          //
          // }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_user_add, false),
        viewBtn: this.vaildData(this.permission.sys_user_view, false),
        delBtn: this.vaildData(this.permission.sys_user_delete, false),
        editBtn: this.vaildData(this.permission.sys_user_edit, false)
      }
    }
  },
  methods: {
    handleReset () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择账号密码重置为123456?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return resetPassword(this.ids)
        })
        .then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    rowSave (row, done, loading) {
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    }
  }
}
</script>

<style>
</style>
