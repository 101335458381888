<template>
  <div class="comp1">
    标题<el-input v-model="title"></el-input>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="型号">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Comp1',
  data () {
    return {
      title: '',
      form: {
        name: ''
      }
    }
  },
  methods: {
    getData () {
      console.log(this.form)
    }
  }
  // watch: {
  //   // 因为不能直接修改 props 里的属性，所以不能直接把 formData 通过v-model进行绑定
  //   // 在这里我们需要监听 formData，当它发生变化时，立即将值赋给 data 里的 form
  //   someData: {
  //     immediate: true,
  //     handler (val) {
  //       this.form = val
  //     }
  //   }
  // }
}
</script>
