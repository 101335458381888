<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @row-click="rowClick"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              v-if="permission.market_sample_delete"
              @click="addRowData">新 增
          </el-button>
          <el-button
              type="danger"
              size="small"
              icon="el-icon-delete"
              v-if="permission.market_sample_delete"
              @click="handleDelete">删 除
          </el-button>
          <!--          <el-button id="files" type="primary"  @click.stop="downModule()">下载模版</el-button>-->
          <!--          <el-upload-->
          <!--              :show-file-list="false"-->
          <!--              :action="handleImport()"-->
          <!--              :headers="uploadHeaders()"-->
          <!--              accept=".zip"-->
          <!--              :on-success="uploadSuc">-->
          <!--            <el-button type="primary">导入</el-button>-->
          <!--          </el-upload>-->
        </div>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { moduleExcel, getList, remove, submit, getLifeCycle } from '@/api/market/sample'
import FileSaver from 'file-saver'
import { mapGetters } from 'vuex'
import { fileSaveUrl } from '@/config/index'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'market/sample',
      form: {},
      search: {},
      lifeCycle: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '样本编号',
            prop: 'code',
            // search: true,
            rules: [{
              required: false,
              message: '样本编号自动生成,无需填写,可修改'
            }],
            disabled: false,
            tip: '样本编号自动生成,无需填写,可修改',
            placeholder: '样本编号自动生成,无需填写,可修改'
          },
          {
            label: '样本条码',
            prop: 'barcode',
            rules: [{
              required: false,
              message: '请输入样本条码'
            }],
            disabled: true
          },
          {
            label: '条码图片',
            prop: 'barcodeUrl',
            hide: true,
            disabled: true
          },
          {
            label: '样本来源',
            prop: 'source',
            type: 'select',
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请输入样本来源'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/source_type'
          },
          {
            label: '医院',
            prop: 'hospitalId',
            type: 'select',
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请输入医院'
            }],
            cascader: ['officeId'],
            cascaderIndex: 1,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/hospital/dict'
          },
          {
            label: '科室',
            prop: 'officeId',
            type: 'select',
            cascader: ['doctorId'],
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请输入科室主键'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/office/dict?hospitalId={{key}}'
          },
          {
            label: '医生',
            prop: 'doctorId',
            type: 'select',
            cascader: ['patientId'],
            search: true,
            rules: [{
              required: true,
              message: '请输入医生主键'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/doctor/dict?officeId={{key}}'
          },
          {
            label: '患者',
            prop: 'patientId',
            type: 'select',
            search: true,
            rules: [{
              required: true,
              message: '请输入患者主键'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/patient/dict?doctorId={{key}}'
          },
          {
            label: '癌种',
            prop: 'cancerType',
            type: 'select',
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请输入癌种'
            }],
            props: {
              value: 'value',
              label: 'name'
            },
            dicUrl: '/setting/set/code/cancer_type'
          },
          {
            label: '样本类型',
            prop: 'type',
            type: 'select',
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请输入样本类型'
            }],
            props: {
              value: 'value',
              label: 'name'
            },
            dicUrl: '/setting/set/code/sample_type'
          },
          {
            label: '是否复发',
            prop: 'isRecurrence',
            type: 'radio',
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }],
            mock: {
              type: 'dic'
            },
            rules: [{
              required: false,
              message: '请输入是否复发'
            }]
          },
          {
            label: '离体时间',
            type: 'datetime',
            prop: 'inVitroTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请输入离体时间'
            }]
          },
          {
            label: '送样时间',
            type: 'datetime',
            prop: 'deliveryTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请输入送样时间'
            }]
          },
          {
            label: '实验时间',
            type: 'datetime',
            prop: 'experimentTime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: false,
              message: '请输入实验时间'
            }]
          },
          {
            label: '样本图片',
            type: 'upload',
            prop: 'photo',
            loadText: '图片上传中，请稍等',
            rules: [{
              required: false,
              message: '请输入样本图片'
            }],
            propsHttp: {
              home: `${fileSaveUrl}`,
              res: 'data'
            },
            tip: '只能上传jpg/png文件',
            action: '/common/fileUpload',
            listType: 'picture-img',
            labelWidth: 120
          },
          {
            label: '样本描述',
            prop: 'description',
            rules: [{
              required: true,
              message: '请输入样本描述'
            }]
          },
          {
            label: '创建人',
            prop: 'creator',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            type: 'datetime',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 00:00:00'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.market_sample_add,
        viewBtn: this.permission.market_sample_view,
        delBtn: this.permission.market_sample_delete,
        editBtn: this.permission.market_sample_edit
      }
    }
  },
  methods: {
    addRowData () {
      this.$refs.crud.rowAdd()
      console.log(this.$refs.crud)
    },
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
      // 延时执行级联加载
      const myThis = this
      setTimeout(function () {
        myThis.$refs.crud.dicInit('cascader')
      }, 500)
    },
    downModule () {
      moduleExcel().then((response) => {
        FileSaver.saveAs(response, '样本管理模板' + '.xls')
      })
    },
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(this.ids).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          } else if (res.msg !== '操作成功') {
            this.$message({
              type: 'success',
              message: res.msg
            })
          }
        })
      })
    },
    rowClick (row) {
      getLifeCycle(row.id).then(res => {
        if (res.success) {
          this.lifeCycle = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>
