<template>
    <div class="good-steps">
        <template v-for="(item, index) in list">
          <div :class="'good-step '+ (active === index?'':active < index?(canEdit - 1 < index?'wait':'can-edit'):'active')" :key="index" :style="'width:' + 100/length + '%' " @click="getStep(index)" v-if="!item.ignore">
            <div class="good-step-index">
              {{index + 1}}
            </div>
            <div class="good-step-title">{{item.name}}</div>
            <div class="line"></div>
          </div>
        </template>
    </div>
</template>

<script>

export default {
  name: 'ExpList',
  props: {
    canEdit: {
      type: Number,
      default: 0
    },
    active: {
      type: Number
    },
    list: {
      type: Array
    }
  },
  computed: {
    length () {
      let index = 0
      for (const item of this.list) {
        if (!item.ignore) {
          index++
        }
      }
      return index
    }
  },
  data () {
    return {

    }
  },
  methods: {
    getStep (index) {
      if (index < this.active || this.canEdit > index) {
        this.$emit('update', index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.good-steps{
  display: flex;
  flex-grow: 0;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
  width: 100%;
  .good-step-index{
    width: 26px;
    height: 26px;
    line-height: 22px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: bold;
    margin: 0 auto;
    border: 2px solid #000;
    background: #fff;
    position: relative;
    z-index: 2;
  }
  .good-step{
    position: relative;
    .line{
      position: absolute;
      height: 2px;
      width: 100%;
      background: #000;
      top: 12px;
      left: calc(50% + 13px);
      z-index: 1;
    }
  }
  .good-step-title{
    margin-top: 10px;
    font-size: 12px;
    white-space: nowrap;
  }
  .good-step.active{
    cursor: pointer;
    .good-step-title {
      color: #409EFF;
    }
    .good-step-index{
      border: 2px solid #409EFF;
      color: #409EFF;

    }
    .line{
      background: #409EFF;
    }
  }
  .good-step.can-edit{
    cursor: pointer;
    .good-step-title {
      color: #abd7ff;
    }
    .good-step-index{
      border: 2px solid #abd7ff;
      color: #abd7ff;

    }
    .line{
      background: #abd7ff;
    }
  }
  .good-step:last-of-type{
    .line{
      display: none;
    }
  }
  .good-step.wait{
    .good-step-title {
      color: #C0C4CC;
    }
    .good-step-index{
      border: 2px solid #C0C4CC;
      color: #C0C4CC;

    }
    .line{
      background:#C0C4CC;
    }
  }
}
</style>
