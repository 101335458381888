import { request } from '@/util/http'

export const remove = (ids) => {
  return request({
    url: '/exp/subCulture/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/exp/subCulture/submit',
    method: 'post',
    data: row
  })
}
