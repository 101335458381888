<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.market_diagnosis_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getPage } from '@/api/market/diagnosis'
import { mapGetters } from 'vuex'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'market/diagnosis',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        labelWidth: 200,
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '诊断来源',
            prop: 'source',
            type: 'select',
            rules: [{
              required: true,
              message: '请选择诊断来源'
            }],
            row: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/diagnosis_source'
          },
          {
            label: '医院',
            prop: 'hospitalId',
            type: 'select',
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请选择医院'
            }],
            cascader: ['officeId'],
            cascaderIndex: 1,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/hospital/dict'
          },
          {
            label: '科室',
            prop: 'officeId',
            type: 'select',
            cascader: ['doctorId'],
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请选择科室'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/office/dict?hospitalId={{key}}`
          },
          {
            label: '医生',
            prop: 'doctorId',
            type: 'select',
            search: true,
            cascader: ['patientId'],
            rules: [{
              required: true,
              message: '请选择医生'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/doctor/dict?officeId={{key}}`
          },
          {
            label: '患者姓名',
            prop: 'patientId',
            type: 'select',
            search: true,
            rules: [{
              required: true,
              message: '请选择患者'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/patient/dict?doctorId={{key}}`
          }
        ],
        group: [{
          label: '影像学诊断',
          arrow: false,
          column: [{
            label: '影像学诊断',
            prop: 'imagingDiagnosis',
            rules: [{
              required: false,
              message: '请输入影像学诊断'
            }]
          }, {
            label: '组织学类型',
            prop: 'histologicalType',
            rules: [{
              required: false,
              message: '请输入组织学类型'
            }]
          },
          {
            label: '组织学分型',
            prop: 'histologicalClassify',
            rules: [{
              required: false,
              message: '请输入组织学分型'
            }]
          },
          {
            label: '细胞学分型',
            prop: 'cytologicalTyping',
            rules: [{
              required: false,
              message: '请输入细胞学分型'
            }]
          },
          {
            label: '组织学分化程度',
            prop: 'histologicalDegree',
            rules: [{
              required: false,
              message: '请输入组织学分化程度'
            }]
          },
          {
            label: '原发灶位置',
            prop: 'primaryFocus',
            rules: [{
              required: false,
              message: '请输入原发灶位置'
            }]
          },
          {
            label: '肿瘤大小',
            prop: 'tumorSize',
            mock: {
              type: 'url',
              header: false
            },
            append: 'cm',
            rules: [{
              required: false,
              message: '请输入肿瘤大小'
            }]
          },
          {
            label: '肿瘤节点数量',
            prop: 'tumorNumber',
            rules: [{
              required: false,
              message: '请输入肿瘤节点数量'
            }]
          },
          {
            label: '是否转移',
            prop: 'isMetastasis',
            type: 'radio',
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }],
            mock: {
              type: 'dic'
            },
            rules: [{
              required: false,
              message: '请输入是否转移'
            }]
          },
          {
            label: '转移部位',
            prop: 'metastaticSite',
            rules: [{
              required: false,
              message: '请输入转移部位'
            }]
          },
          {
            label: '是否感染',
            prop: 'isInfection',
            type: 'radio',
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }],
            mock: {
              type: 'dic'
            },
            rules: [{
              required: false,
              message: '请输入是否感染'
            }]
          },
          {
            label: '感染病毒名称',
            prop: 'virusName',
            rules: [{
              required: false,
              message: '请输入感染病毒名称'
            }]
          },
          {
            label: '是否肝硬化',
            prop: 'isCirrhosis',
            type: 'radio',
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }],
            mock: {
              type: 'dic'
            },
            rules: [{
              required: false,
              message: '请输入是否肝硬化'
            }]
          },
          {
            label: '是否包膜浸润',
            prop: 'isImmersion',
            type: 'radio',
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }],
            mock: {
              type: 'dic'
            },
            rules: [{
              required: false,
              message: '请输入是否包膜浸润'
            }]
          },
          {
            label: '门静脉癌栓PVTT',
            prop: 'pptv',
            rules: [{
              required: false,
              message: '请输入门静脉癌栓'
            }]
          },
          {
            label: '微血管浸润MVI',
            prop: 'mvi',
            rules: [{
              required: false,
              message: '请输入微血管浸润'
            }]
          }]
        },
        {
          label: '血清学检测',
          arrow: false,
          column: [{
            label: 'AFP表达(ug/L)',
            prop: 'afp',
            rules: [{
              required: false,
              message: '请输入afp表达'
            }]
          },
          {
            label: 'CEA表达(UG/L)',
            prop: 'cea',
            rules: [{
              required: false,
              message: '请输入cea表达'
            }]
          },
          {
            label: 'CA19-9表达(UG/ML)',
            prop: 'ca199',
            rules: [{
              required: false,
              message: '请输入ca19_9表达'
            }]
          }
          ]
        },
        {
          label: '分期',
          arrow: false,
          column: [
            {
              label: 'BCLC分期',
              prop: 'bclc',
              rules: [{
                required: false,
                message: '请输入bclc分期'
              }]
            },
            {
              label: 'child-pugh分级',
              prop: 'childPugh',
              rules: [{
                required: false,
                message: '请输入child_pugh分级'
              }]
            },
            {
              label: 'stage分期',
              prop: 'stage',
              rules: [{
                required: false,
                message: '请输入stage分期'
              }]
            },
            {
              label: 'PS评分',
              prop: 'ps',
              rules: [{
                required: false,
                message: '请输入ps评分'
              }]
            }]
        },
        {
          label: '基因诊断',
          arrow: false,
          column: [

            {
              label: '突变基因（位点、丰度）',
              prop: 'mutantGene',
              rules: [{
                required: false,
                message: '请输入突变基因（位点、丰度）'
              }]
            },
            {
              label: 'PD-L1',
              prop: 'pdL1',
              rules: [{
                required: false,
                message: '请输入PD-L1'
              }]
            },
            {
              label: 'PD-1',
              prop: 'pd1',
              rules: [{
                required: false,
                message: '请输入PD-1'
              }]
            },
            {
              label: 'tmb',
              prop: 'tmb',
              rules: [{
                required: false,
                message: '请输入tmb'
              }]
            },
            {
              label: 'msi',
              prop: 'msi',
              rules: [{
                required: false,
                message: '请输入msi'
              }]
            },
            {
              label: '遗传基因',
              prop: 'dna',
              rules: [{
                required: false,
                message: '请输入遗传基因'
              }]
            },
            {
              label: 'mlh1',
              prop: 'mlh1',
              rules: [{
                required: false,
                message: '请输入mlh1'
              }]
            },
            {
              label: 'pms2',
              prop: 'pms2',
              rules: [{
                required: false,
                message: '请输入pms2'
              }]
            },
            {
              label: 'msh6',
              prop: 'msh6',
              rules: [{
                required: false,
                message: '请输入msh6'
              }]
            },
            {
              label: 'msh2',
              prop: 'msh2',
              rules: [{
                required: false,
                message: '请输入msh2'
              }]
            },
            {
              label: '备注',
              prop: 'remark',
              span: 24,
              rules: [{
                required: false,
                message: '请输入备注'
              }]
            },
            {
              label: '创建人',
              prop: 'creator',
              rules: [{
                required: false,
                message: '请输入备注'
              }],
              disabled: true,
              hide: true
            },
            {
              label: '创建时间',
              prop: 'createTime',
              type: 'datetime',
              format: 'yyyy-MM-dd',
              valueFormat: 'yyyy-MM-dd HH:mm:ss',
              rules: [{
                required: false,
                message: '请输入备注'
              }],
              disabled: true,
              hide: true,
              value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 00:00:00'
            }]
        }]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.market_diagnosis_add,
        viewBtn: this.permission.market_diagnosis_view,
        delBtn: this.permission.market_diagnosis_delete,
        editBtn: this.permission.market_diagnosis_edit
      }
    }
  },
  methods: {
    onLoad (page) {
      getPage(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
      // 延时执行级联加载
      const myThis = this
      setTimeout(function () {
        myThis.$refs.crud.dicInit('cascader')
      }, 500)
    },
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    }
  }
}
</script>
