<template>
  <div>
    <avue-form ref="form" :option="option" v-model="form" :key="type" :upload-preview="viewFile">
<!--      <template slot="docType" slot-scope="{file}">-->
<!--        <span>-->
<!--          <a :href="file.url" target="_blank"><i class="el-icon-document el-icon&#45;&#45;left"></i>{{file.name}}</a>-->
<!--          <i class="el-icon-close" @click="delFile(file)"></i>-->
<!--        </span>-->
<!--      </template>-->
    </avue-form>
  </div>
</template>

<script>
import option from './index'
import { getCode } from '@/api/exp/experimentassets'

export default {
  name: 'Test1',
  props: {
    value: [Array, Object],
    type: [Number, String]
  },
  data () {
    return {
      option: option[this.type]
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        if (value.specifications !== '' && value.specifications !== undefined && value.brand !== '' && value.model !== undefined && value.code === '') {
          getCode({ objectType: this.type, brand: value.brand, model: value.model, specifications: value.specifications }).then(res => {
            if (res.success) {
              value.code = res.data
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          })
        }
        if (value.specifications === '' || value.specifications === undefined) {
          value.code = ''
        }
        this.$emit('input', value)
      }
    }
  },
  methods: {
    viewFile (file, column, done) {
      if (file.type === 'img') {
        done()
      } else {
        // window.location.href = 'http://localhost:6626/admin/viewImg/' + file.url
        window.open(file.url)
      }
    },
    refreshForm (value) {
      value = value || this.type
      this.option = option[value]
      this.form = this.value
      this.$refs.form.init()
      this.$refs.form.updateDic()
      this.$refs.form.dicInit()
    }
  },
  watch: {
    type (value) {
      this.refreshForm(value)
    }
  }
}
</script>

<style scoped>

</style>
