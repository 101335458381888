<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.setting_experimentdatatemplate_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'setting/operator',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }],
            hide: true,
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false
          },
          {
            label: '操作项类型',
            prop: 'type',
            type: 'select',
            cascader: ['objectType'],
            rules: [{
              required: true,
              message: '请输入对象类型-代码'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/exp_object_type',
            width: 80
          },
          {
            label: '对象类型',
            prop: 'objectType',
            type: 'select',
            rules: [{
              required: true,
              message: '请输入对象类型-代码'
            }],
            props: {
              value: 'dictKey',
              label: 'dictValue'
            },
            dicUrl: '/sys/dict/code/operator_type/{{type}}',
            width: 80
          },
          {
            label: '配置方式',
            prop: 'status',
            type: 'radio',
            rules: [{
              required: true,
              message: '请输入对象ID'
            }],
            dic: [{
              label: '选项1',
              value: 0
            }, {
              label: '选项2',
              value: 1
            }],
            hide: true
          },
          {
            label: '对象ID',
            prop: 'objectId',
            rules: [{
              required: true,
              message: '请输入对象ID'
            }],
            hide: true
          },
          {
            label: '名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入名称'
            }],
            width: 120
          },
          {
            label: '编号',
            prop: 'code',
            width: 120
          },
          {
            label: '属性1',
            prop: 'param1',
            width: 150
          },
          {
            label: '属性2',
            prop: 'param2',
            width: 150
          },
          {
            label: '参数',
            prop: 'content',
            editDisplay: false
          },
          {
            label: '参数1',
            prop: 'content2',
            type: 'dynamic',
            span: 24,
            hide: true,
            children: {
              align: 'center',
              headerAlign: 'center',
              rowAdd: (done) => {
                done()
              },
              rowDel: (row, done) => {
                done()
              },
              column: [{
                label: '名称',
                prop: 'name'
              },
              {
                label: '类型',
                prop: 'type',
                type: 'select',
                dicData: [
                  {
                    label: '文本',
                    value: 1
                  }, {
                    label: '下拉',
                    value: 2
                  }, {
                    label: '文本域',
                    value: 3
                  }
                ]
              },
              {
                label: '值',
                prop: 'val'
              }]
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.setting_experimentdatatemplate_add,
        viewBtn: this.permission.setting_experimentdatatemplate_view,
        delBtn: this.permission.setting_experimentdatatemplate_delete,
        editBtn: this.permission.setting_experimentdatatemplate_edit
      }
    }
  },
  mounted () {
    // 放在数据加载完后执行
    this.$refs.crud.dicInit('cascader')
  },
  methods: {
  }
}
</script>
