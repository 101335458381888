<template>
  <basic-container>
    <good-steps :active="step - 1" :canEdit="stepNo" :list="formList" @update="changeStep"></good-steps>
    <div v-if="step > 1" style="margin-top: 20px">
      <el-card class="box-card" v-for="(label,index) in formList[step-1].forms" :key="index">
        <div slot="header" class="clearfix">
          <span>{{ label.label }}</span>
        </div>
        <div>
          <testProject
              ref="testProject"
              :type="label.value"
              v-model="label.form" />
        </div>
      </el-card>
    </div>
    <div v-else style="margin-top: 20px">
      <step1 :exp-id="experiment.id" ref="step1Valid" v-model="formList[0].form"></step1>
    </div>
    <div class="button-group">
      <el-button icon="el-icon-arrow-left" v-if="step > 1" @click="prevStep">上一步</el-button>
      <el-button @click="nextStep" type="primary" v-if="formList.length > step && !isFinal">下一步<i class="el-icon-arrow-right el-icon--right"></i></el-button>
      <el-button @click="save" icon="el-icon-check" type="primary" v-if="isFinal">保存</el-button>
    </div>
<!--    <el-dialog-->
<!--        title="设置步骤"-->
<!--        :visible.sync="stepSwitch"-->
<!--        width="20%"-->
<!--        :close-on-click-modal="false"-->
<!--        :close-on-press-escape="false"-->
<!--        :show-close="false"-->
<!--        center>-->
<!--      <div>-->
<!--        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>-->
<!--        <div style="margin: 15px 0;"></div>-->
<!--        <el-checkbox-group v-model="checkedStep" @change="handleCheckedCitiesChange">-->
<!--          <el-checkbox style="display: block" v-for="(s,index) in formList" :label="index" :key="index" :disabled="index===0">{{index+1}}.{{s.name}}</el-checkbox>-->
<!--        </el-checkbox-group>-->
<!--      </div>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" @click="handleStepInfo">确 定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->
  </basic-container>
</template>

<script>
import testProject from '@/components/awd-test-step/test-form'
import step1 from '@/views/exp/primaryculture/step1'
import { getCurrentByType } from '@/api/setting/experimenttemplate'
import { getDetail as getExperiment } from '@/api/exp/experiment'
import { submit } from '@/api/exp/primaryculture'
import goodSteps from '@/components/view-comp/good-steps'

export default {
  components: {
    testProject,
    goodSteps,
    step1
  },
  data () {
    return {
      isFinal: false,
      checkAll: true,
      isIndeterminate: false,
      checkedStep: [],
      experiment: {},
      templateType: '',
      step: 1,
      formList: [],
      stepSwitch: false,
      stepNo: 0
    }
  },
  created () {
    this.templateType = this.$route.query.templateType
    this.experiment.id = this.$route.query.experimentId
    if (!this.experiment.id) {
      this.stepSwitch = true
    }
  },
  mounted () {
    this.detail()
  },
  methods: {
    changeStep (e) {
      this.step = e + 1
      this.stepIsFinal()
    },
    handleStepInfo () {
      console.log(this.checkedStep)
      this.formList = this.formList.map((item) => {
        item.ignore = true
        return item
      })
      for (const index of this.checkedStep) {
        this.formList[index].ignore = false
      }
      this.stepSwitch = false
    },
    handleCheckAllChange (val) {
      this.checkedStep = val
        ? this.formList.map((item, index) => {
          return index
        })
        : [0]
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      const checkedStep = value.length
      this.checkAll = checkedStep === this.formList.length
      this.isIndeterminate = checkedStep > 0 && checkedStep < this.formList.length
    },
    detail () {
      // 若是第一步
      if (this.step === 1 && this.experiment.id == null) {
        getCurrentByType(this.templateType).then(res => {
          if (res.data.content != null) {
            this.formList = res.data.content
            this.experiment.templateId = res.data.id
            this.checkedStep = this.formList.map((item, index) => {
              return index
            })
            this.formList = this.formList.map(item => {
              for (let i = 0; i < item.forms.length; i++) {
                item.forms[i].form = item.forms[i].form || {}
              }
              return item
            })
          }
        })
      } else {
        getExperiment(this.experiment.id).then(res => {
          this.stepNo = res.data.stepNo
          this.step = res.data.stepNo
          this.experiment = res.data
          this.formList = this.experiment.content
          this.stepIsFinal()
        })
      }
    },
    stepIsFinal () {
      let s = true
      if (this.step === this.formList.length) {
        s = true
      } else {
        for (let i = this.step - 1; i < this.formList.length; i++) {
          if (!this.formList[i].ignore) {
            s = false
            break
          } else {
            s = true
          }
        }
      }
      this.isFinal = s
    },
    forwardStep () {
      if (this.formList[this.step].ignore) {
        this.step++
        this.forwardStep()
      } else {
        this.step++
      }
      this.stepIsFinal()
    },
    backStep () {
      if (this.formList[this.step - 2].ignore) {
        this.step--
        this.backStep()
      } else {
        this.step--
      }
    },
    prevStep () {
      this.backStep()
      this.isFinal = false
    },
    nextStep () {
      this.experiment.stepNo = this.step
      this.stepNo = (this.stepNo < this.step ? this.step : this.stepNo)
      this.experiment.stepName = this.formList[this.step - 1].name
      this.experiment.content = this.formList
      if (this.step === 1) {
        const testForm = this.$refs.step1Valid
        testForm.$refs.form.validate((valid, done, msg) => {
          done()
          if (!valid) {
            this.$message.error(Object.values(msg)[0][0].message)
          } else {
            submit(this.experiment).then(res => {
              if (res.success) {
                this.experiment = res.data
                this.formList = this.experiment.content
                this.forwardStep()
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          }
        })
      } else {
        this.$refs.testProject[0].refreshForm()
        this.validate().then((valid) => {
          if (valid) {
            submit(this.experiment).then(res => {
              if (res.success) {
                this.experiment = res.data
                this.formList = this.experiment.content
                this.forwardStep()
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          }
        })
      }
    },
    validate () {
      const that = this
      return new Promise((resolve) => {
        const testForm = this.$refs.testProject
        getValid(0)
        function getValid (i) {
          testForm[i].$refs.form.validate((valid, done, msg) => {
            done()
            if (valid) {
              if (i + 1 >= testForm.length) {
                resolve(valid)
              } else {
                getValid(i + 1)
              }
            } else {
              console.log('=====>', valid)
              that.$message.error(Object.values(msg)[0][0].message)
              resolve(valid)
            }
          })
        }
      })
    },
    save () {
      this.experiment.stepNo = this.step
      this.experiment.stepName = this.formList[this.step - 1].name
      this.experiment.content = this.formList
      submit(this.experiment).then(res => {
        if (res.success) {
          this.experiment = res.data
          this.formList = this.experiment.content
          this.$router.push({
            path: '/exp/primaryculture/index'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }

}
</script>
<style lang="scss">
.step-title{
  white-space: nowrap;
  text-overflow: ellipsis
}
.button-group{
  text-align: center;
}
</style>
