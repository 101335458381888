<template>
  <basic-container>
    <avue-crud
            :defaults.sync="defaults"
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            :before-close="beforeClose"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.setting_setting_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getDetail, remove } from '@/api/setting/setting'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'setting/set',
      form: {},
      search: {},
      defaults: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 6,
        column: [
          {
            label: '全局设置类型',
            prop: 'defineType',
            searchSpan: 8,
            searchLabelWidth: 100,
            type: 'select',
            rules: [{
              required: true,
              message: '请选择类型'
            }],
            dicData: [
              {
                label: '样本培养成功传代次数',
                value: 'iterations'
              },
              {
                label: '癌种定义',
                value: 'cancer_type'
              }, {
                label: '样本类型定义',
                value: 'sample_type'
              }
            ],
            search: true,
            hide: true
          },
          {
            label: '设置参数代码',
            prop: 'code',
            rules: [{
              required: true,
              message: '请输入设置参数代码'
            }],
            disabled: true
          },
          {
            label: '设置参数名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入设置参数名称'
            }]
          },
          {
            label: '设置参数值',
            prop: 'value',
            rules: [{
              required: false,
              message: '请输入设置参数值'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.setting_setting_add,
        viewBtn: this.permission.setting_setting_view,
        delBtn: this.permission.setting_setting_delete,
        editBtn: this.permission.setting_setting_edit
      }
    }
  },
  watch: {
    'form.defineType' (defineType) {
      if (defineType != null) {
        this.form.code = defineType
      }
      if (defineType === 'iterations') {
        this.defaults.name.label = '培养成功迭代次数'
        this.defaults.value.label = '次数'
        this.defaults.value.display = true
        this.defaults.value.rules = [{ required: true, message: '请设置迭代成功次数' }]
        this.defaults.value.disabled = false
      } else {
        this.defaults.value.rules = [{ required: false }]
        this.defaults.value.disabled = true
        this.defaults.value.display = false
        if (defineType === 'cancer_type') {
          this.defaults.name.label = '癌种名称'
        }
        if (defineType === 'sample_type') {
          this.defaults.name.label = '样本类型名称'
        }
      }
    }
  },
  methods: {
    searchChange (params, done) {
      this.search = { code: params.defineType }
      this.onLoad(this.page, this.search)
      done()
    },
    searchReset () {
      this.defaults.code.hide = false
      this.onLoad(this.page)
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
          this.form.defineType = this.form.code
        })
      }
      done()
    },
    beforeClose (done) {
      this.defaults.name.label = '名称'
      this.defaults.value.label = '参数值'
      done()
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(this.ids).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    }
  }
}
</script>
