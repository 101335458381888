<template>
  <div :class="'tubeStand s' + col" :style="disabled?'filter: grayscale(50%);':''">
    <div class="tubehead">
      <span v-for="(item, index) in getName(col)" :key="item">{{index + 1}}</span>
    </div>
    <div style="display: flex">
      <div class="tuberow">
        <span v-for="item in getName(row,'letter')" :key="item">{{item}}</span>
      </div>
      <div :class="freeze?'tubeList freeze':'tubeList normal'">
        <div @click="handleTube(index, item)" :class="item.checked?'tubeHole active':'tubeHole'" v-for="(item, index) in tubeList" :key="index">
          <el-tooltip class="tubeHole-tip" effect="dark" :disabled="!showtip" :content="item.name" placement="top">
            <div class="tubeHole-freeze" v-if="item.freeze"><i class="el-icon-lock"></i></div>
            <div v-else></div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const grid = {
  g6: [1, 6],
  g12: [2, 6],
  g24: [4, 6],
  g36: [6, 6],
  g48: [6, 8],
  g96: [8, 12],
  g384: [16, 24]
}
export default {
  name: 'TestTube',
  props: {
    showtip: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: [Array, String],
    freeze: {
      type: Boolean,
      default: false
    },
    hole: {
      type: [Number, String],
      default: 24
    }
  },
  // computed: {
  //   value: {
  //     get () {
  //       return this.value
  //     },
  //     set (value) {
  //       this.$emit('input', this.getValue(this.value))
  //     }
  //   }
  // },
  watch: {
    hole (value) {
      this.refresh()
    },
    value (value) {
      this.getValue(value)
    }
  },
  created () {
    this.refresh()
  },
  mounted () {
    this.getValue(this.value)
  },
  data () {
    return {
      row: 0,
      col: 0,
      tubeList: []
    }
  },
  methods: {
    refresh () {
      const arr = []
      let row, col
      const hole = parseInt(this.hole)
      if (grid[`g${hole}`]) {
        row = grid[`g${hole}`][0]
        col = grid[`g${hole}`][1]
      } else {
        row = 4
        col = 6
      }
      this.row = row
      this.col = col
      const count = row * col
      for (let i = 0; i < count; i++) {
        arr.push({
          name: `${letters[Math.ceil((i + 1) / col) - 1]}${i % col + 1}`,
          index: i,
          checked: false,
          freeze: false
        })
      }
      this.tubeList = arr
    },
    getValue (value) {
      for (let i = 0; i < value.length; i++) {
        this.tubeList[i].checked = (value[i] === 1 || value[i] === 2)
        this.tubeList[i].freeze = value[i] === 2
      }
    },
    getName (number, key) {
      if (key === 'letter') {
        const arr = []
        for (let i = 0; i < number; i++) {
          arr.push(letters[i])
        }
        return arr
      }
      return new Array(number)
    },
    handleTube (index, item) {
      if (this.disabled) {
        return
      }
      if (this.freeze) {
        if (!this.tubeList[index].checked) {
          return
        }
        this.tubeList[index].freeze = !item.freeze
        const arr = []
        for (let i = 0; i < this.tubeList.length; i++) {
          if (this.tubeList[i].freeze) {
            arr.push(2)
          } else if (this.tubeList[i].checked) {
            arr.push(1)
          } else {
            arr.push(0)
          }
        }
        this.$emit('input', arr)
      } else {
        this.tubeList[index].checked = !item.checked
        const arr = []
        for (let i = 0; i < this.tubeList.length; i++) {
          if (this.tubeList[i].checked) {
            arr.push(1)
          } else {
            arr.push(0)
          }
        }
        this.$emit('input', arr)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tubeHole-tip{
  width: 100%;
  height: 100%;
}
.tubeStand{
  padding: 20px 20px 10px 20px;
  width: 552px;
  border-radius: 30px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
}
.tubeList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
}
$width-1x: 80px;
$margin-hole: $width-1x/10;
$width-real: $width-1x - $margin-hole;
.tubeHole{
  border-radius: 50%;
  border: 1px solid #DCDFE6;
  transition: all .2s;
  box-sizing: border-box;
  .tubeHole-freeze{
    color:#409EFF;
    text-align: center;
  }
}
.s6 .tubeHole{
  width: $width-real;
  height: $width-real;
  font-size:$width-real / 2 ;
  line-height:$width-real;
  flex-basis: 15%;
}
.s8 .tubeHole{
  width: $width-real * 0.75;
  height: $width-real * 0.75;
  font-size:$width-real * 0.75 / 2 ;
  line-height: $width-real * 0.75;
  flex-basis: 11%;
  margin: 0 $margin-hole/2 * 0.75 $margin-hole * 0.75 $margin-hole/2 * 0.75;
}
.s12 .tubeHole{
  width: $width-real * 0.5;
  height: $width-real * 0.5;
  font-size:$width-real * 0.5 / 2 ;
  line-height: $width-real * 0.5;
  flex-basis: 7.4%;
  margin: 0 $margin-hole/2 * 0.5 $margin-hole * 0.5 $margin-hole/2 * 0.5;
}
.s24 .tubeHole{
  width: $width-real * 0.25;
  line-height: $width-real * 0.25;
  font-size:$width-real * 0.25 / 2 ;
  height: $width-real * 0.25;
  margin: 0 $margin-hole/2 * 0.25 $margin-hole * 0.25 $margin-hole/2 * 0.25;
}
.tuberow{
  flex-shrink:0;
  width: 30px;
  span{
    box-sizing: border-box;
    color: #C0C4CC;
    display: block;
    text-align: center;
  }
}
.s6 .tuberow{

  span{
    height: $width-1x;
    line-height: $width-1x;
  }
}
.s8 .tuberow{

  span{
    height: $width-1x * 0.75;
    line-height: $width-1x * 0.75;
  }
}
.s12 .tuberow{

  span{
    height: $width-1x * 0.5;
    line-height: $width-1x * 0.5;
  }
}
.s24 .tuberow{

  span{
    height: $width-1x * 0.25;
    line-height: $width-1x * 0.25;
  }
}
.tubehead{
  display: flex;
  height: 30px;
  line-height: 30px;
  color: #C0C4CC;
  justify-content: space-between;
  padding-left: 30px;
  span{
    display: block;
    width: 60px;
    text-align: center;
  }
}
.s24 .tubehead{
  span{
    width: $width-1x * 0.25;
  }
}
.s12 .tubehead{
  span{
    width: $width-1x * 0.5;
  }
}
.s6 .tubehead{
  span{
    width: $width-1x;
  }
}
.s8 .tubehead{
  span{
    width: $width-1x * 0.75;
  }
}
.normal{
  .tubeHole:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #409EFF;
    background: rgb(217, 236, 255);
    cursor: pointer;
  }
  .tubeHole.active{
    background: #409EFF;
    border: 1px solid #409EFF;
  }
}

.freeze{
  .tubeHole{
    cursor: not-allowed;
  }
  .tubeHole.active:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .tubeHole.active{
    background: rgb(217, 236, 255);
    border: 1px solid #409EFF;
  }
}
</style>
