import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/organ/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const remove = (ids) => {
  return request({
    url: '/exp/organ/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/exp/organ/submit',
    method: 'post',
    data: row
  })
}

export const deleteOrgan = (id) => {
  return request({
    url: '/exp/organ/delete/' + id,
    method: 'post'
  })
}
