<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.sys_center_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'
import { submit } from '@/api/sys/center'

export default {
  mixins: [mixin],
  data () {
    const validateMobile = (rule, value, callback) => {
      if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号格式'))
      } else {
        callback()
      }
    }
    return {
      module: 'sys/center',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 6,
        column: [
          {
            label: '实验中心',
            maxlength: 20,
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入名称'
            }],
            search: true,
            searchSpan: 10
          },
          {
            label: '地址',
            maxlength: 20,
            prop: 'address',
            rules: [{
              required: true,
              message: '请输入地址'
            }],
            width: 105,
            overHidden: true
          },
          // {
          //   label: '联系人',
          //   prop: 'contact',
          //   rules: [{
          //     required: true,
          //     message: '请输入联系人'
          //   }]
          // },
          {
            label: '联系人',
            maxlength: '20',
            prop: 'contact',
            type: 'select',
            rules: [{
              required: true,
              message: '请输入联系人'
            }],
            search: true,
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/user/dict'
          },
          {
            label: '联系电话',
            prop: 'mobilephone',
            maxlength: 20,
            rules: [{
              required: true,
              validator: validateMobile,
              trigger: 'blur'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            maxlength: 200,
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            hide: true
          },
          {
            label: '创建人',
            prop: 'creator',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime1',
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.sys_center_add,
        viewBtn: this.permission.sys_center_view,
        delBtn: this.permission.sys_center_delete,
        editBtn: this.permission.sys_center_edit
      }
    }
  },
  methods: {
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    rowSave (row, done, loading) {
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    }
  }
}
</script>
