<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.exp_equipment_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'
import { remove } from '@/api/exp/equipment'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'exp/equipment',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 6,
        column: [
          {
            label: '设备编号',
            prop: 'code',
            rules: [{
              required: false,
              message: '请输入设备编号'
            }],
            disabled: true,
            search: true
          },
          {
            label: '设备类型',
            prop: 'objectType',
            type: 'select',
            rules: [{
              required: true,
              message: '请输入设备类型-代码'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/operator_type/3',
            search: true
          },
          {
            label: '设备名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入设备类型-代码'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符',
            overHidden: true
          },
          {
            label: '规格',
            prop: 'specifications',
            rules: [{
              required: true,
              message: '请输入规格'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '二级规格',
            prop: 'secondSpecifications',
            rules: [{
              required: false,
              message: '请输入二级规格'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '存放位置',
            prop: 'location'
          },
          {
            label: '品牌',
            prop: 'brand',
            rules: [{
              required: true,
              message: '请输入品牌'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '型号',
            prop: 'model',
            rules: [{
              required: true,
              message: '请输入型号'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '供应商',
            prop: 'supplier',
            rules: [{
              required: true,
              message: '请输入供应商'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '批次',
            prop: 'batchNo',
            rules: [{
              required: true,
              message: '请输入批次'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '用途',
            prop: 'purpose',
            rules: [{
              required: false,
              message: '请输入用途'
            }],
            width: 105,
            overHidden: true,
            maxlength: 100,
            tip: '最长可输入100个字符'
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            type: 'textarea',
            maxlength: 200,
            tip: '最长可输入200个字符'
          },
          {
            label: '创建人',
            prop: 'creator',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            type: 'datetime',
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true
          },
          {
            label: '创建时间',
            prop: 'createTime1',
            viewDisplay: false,
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.exp_equipment_add,
        viewBtn: this.permission.exp_equipment_view,
        delBtn: this.permission.exp_equipment_delete,
        editBtn: this.permission.exp_equipment_edit
      }
    }
  },
  methods: {
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(this.ids).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    }
  }
}
</script>
