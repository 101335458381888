<template>
  <basic-container>
    <div class="exp-content">
      <div class="exp-date-block">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="时间">
            <el-date-picker
                    :picker-options="pickerOptions"
                    end-placeholder="结束日期"
                    range-separator="至"
                    start-placeholder="开始日期"
                    type="datetimerange"
                    v-model="queryDate"
                    value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="癌种">
            <el-select clearable placeholder="请选择" v-model="cancerType">
              <el-option
                  :label="item.name"
                  :key="item.value"
                  v-for="item in cancerOptions"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="query()" type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="exp-wrap">
        <div class="exp-wrap-box" :key="index" v-for="(item, index) in finishedData">
          <expList :active="item.noFinished" :max="item.total" :prob="item.rate * 100" :title="item.name" color="#67C23A" tips="进行中/全部"/>
        </div>
      </div>
      <div class="exp-wrap">
        <div class="exp-wrap-box" :key="index"  v-for="(item, index) in finishedData">
          <expList :title="item.name" :active="item.finished" :max="item.total" color="#67C23A" :prob="item.rate * 100" tips="已完成/全部"/>
        </div>
      </div>
        <div class="exp-wrap">
          <div class="exp-wrap-box" v-for="(item, index) in testList" :key="index">
          <expList :title="item.name" :active="item.success" :max="item.total" :prob="item.rate * 100" tips="成功/总计"/>
        </div>
      </div>
    </div>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'
import expList from '@/components/view-comp/exp-list'
import { statisticTags, onGoingFinished } from '@/api/exp/dashboard'
import { getListByCode } from '@/api/setting/setting'
export default {
  mixins: [mixin],
  components: {
    expList
  },
  data () {
    return {
      testList: [],
      cancerOptions: [],
      cancerType: '',
      queryDate: '',
      finishedData: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.exp_reagent_add,
        viewBtn: this.permission.exp_reagent_view,
        delBtn: this.permission.exp_reagent_delete,
        editBtn: this.permission.exp_reagent_edit
      }
    }
  },
  created () {
    this.getList()
    this.cancers()
    this.underwayFinished()
  },
  methods: {
    getList () {
      statisticTags().then(res => {
        this.testList = res.data
        console.log(res)
      })
    },
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    cancers () {
      getListByCode('cancer_type').then(res => {
        this.cancerOptions = res.data
      })
    },
    query () {
      onGoingFinished(this.queryDate, this.cancerType).then(res => {
        if (res.success) {
          this.finishedData = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    underwayFinished () {
      onGoingFinished(this.queryDate, this.cancerType).then(res => {
        if (res.success) {
          this.finishedData = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.exp-list{
  margin-top: 20px;
}
.exp-wrap{
  display: flex;
  min-width: 800px;
  justify-content: space-between;
  margin-bottom: 20px;
  .exp-wrap-box{
    width: 19%;
  }
}
</style>
