<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.exp_experiment_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'exp/experiment',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '实验模板ID',
            prop: 'templateId',
            rules: [{
              required: true,
              message: '请输入实验模板ID'
            }]
          },
          {
            label: '实验编号',
            prop: 'experimentCode',
            rules: [{
              required: true,
              message: '请输入实验编号'
            }]
          },
          {
            label: '上一代实验ID',
            prop: 'previousExperimentId',
            rules: [{
              required: true,
              message: '请输入上一代实验ID'
            }]
          },
          {
            label: '样本ID',
            prop: 'sampleId',
            rules: [{
              required: true,
              message: '请输入样本ID'
            }]
          },
          {
            label: '是否培养成功',
            prop: 'isSuccess',
            rules: [{
              required: true,
              message: '请输入是否培养成功'
            }]
          },
          {
            label: '实验员ID',
            prop: 'experimenter',
            rules: [{
              required: true,
              message: '请输入实验员ID'
            }]
          },
          {
            label: '实验时间',
            prop: 'experimentTime',
            rules: [{
              required: true,
              message: '请输入实验时间'
            }]
          },
          {
            label: '当前实验步骤',
            prop: 'stepNo',
            rules: [{
              required: true,
              message: '请输入当前实验步骤'
            }]
          },
          {
            label: '实验内容',
            prop: 'content',
            rules: [{
              required: true,
              message: '请输入实验内容'
            }]
          },
          {
            label: '类器官代次',
            prop: 'generation',
            rules: [{
              required: true,
              message: '请输入类器官代次'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.exp_experiment_add,
        viewBtn: this.permission.exp_experiment_view,
        delBtn: this.permission.exp_experiment_delete,
        editBtn: this.permission.exp_experiment_edit
      }
    }
  },
  methods: {
  }
}
</script>
