<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.exp_experimentassets_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'exp/experimentassets',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '实验编号',
            prop: 'experimentId',
            rules: [{
              required: true,
              message: '请输入实验编号'
            }]
          },
          {
            label: '步骤',
            prop: 'stepNo',
            rules: [{
              required: true,
              message: '请输入步骤'
            }]
          },
          {
            label: '资产类型-代码 1:试剂 2:耗材 3:设备 4:其他',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入资产类型-代码 1:试剂 2:耗材 3:设备 4:其他'
            }]
          },
          {
            label: '对象类型 与type关联',
            prop: 'objectType',
            rules: [{
              required: true,
              message: '请输入对象类型 与type关联'
            }]
          },
          {
            label: '对象编号',
            prop: 'objectCode',
            rules: [{
              required: true,
              message: '请输入对象编号'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.exp_experimentassets_add,
        viewBtn: this.permission.exp_experimentassets_view,
        delBtn: this.permission.exp_experimentassets_delete,
        editBtn: this.permission.exp_experimentassets_edit
      }
    }
  },
  methods: {
  }
}
</script>
