<template>
  <basic-container>
    <good-steps :active="step - 1" :canEdit="stepNo" :list="formList" @update="changeStep"></good-steps>
    <div style="margin-top: 20px" v-if="step > 1">
      <el-card :key="index" class="box-card" v-for="(label,index) in formList[step-1].forms">
        <div class="clearfix" slot="header">
          <span>{{ label.label }}</span>
        </div>
        <div>
          <testProject
              :type="label.value"
              ref="testProject"
              v-model="label.form" />
        </div>
      </el-card>
    </div>
    <div style="margin-top: 20px" v-else>
      <step1 ref="step1Valid" v-model="formList[0].form"></step1>
    </div>
    <div class="button-group">
      <el-button @click="prevStep" icon="el-icon-arrow-left" v-if="step > 1">上一步</el-button>
      <el-button @click="nextStep" type="primary" v-if="formList.length > step && !isFinal">下一步<i class="el-icon-arrow-right el-icon--right"></i></el-button>
      <el-button @click="save" icon="el-icon-check" type="primary" v-if="isFinal">保存</el-button>
    </div>
  </basic-container>
</template>

<script>
import testProject from '@/components/awd-test-step/test-form'
import step1 from '@/views/exp/subculture/step1'
import goodSteps from '@/components/view-comp/good-steps'
import { getCurrentByType } from '@/api/setting/experimenttemplate'
import { getDetail as getExperiment } from '@/api/exp/experiment'
import { submit } from '@/api/exp/subculture'

export default {
  components: {
    testProject,
    goodSteps,
    step1
  },
  data () {
    return {
      experiment: {},
      templateType: '',
      isPassage: 0,
      step: 1,
      stepNo: 0,
      formList: [],
      isFinal: false
    }
  },
  created () {
    this.templateType = this.$route.query.templateType
    this.experiment.id = this.$route.query.experimentId
    this.isPassage = this.$route.query.isPassage
  },
  mounted () {
    this.detail()
  },
  methods: {
    detail () {
      if (this.step === 1 && this.isPassage != null) {
        // 从传代列表操作栏传代的按钮进入
        getCurrentByType(this.templateType).then(res => {
          if (res.data.content != null) {
            this.formList = res.data.content
            this.experiment.templateId = res.data.id
            this.formList = this.formList.map(item => {
              for (let i = 0; i < item.forms.length; i++) {
                item.forms[i].form = item.forms[i].form || {}
              }
              return item
            })
          }
        })
        getExperiment(this.experiment.id).then(res => {
          const preExp = res.data.content
          this.formList[0].form = this.formList[0].form || {}
          this.formList[0].form.tubeList = []
          // 传代实验第12步的铺板信息
          this.formList[0].form.tubeList.push(preExp[11].forms[0])
          this.formList[0].form.tubeList[0].id = this.experiment.id
          this.formList[0].form.tubeList[0].expCode = res.data.experimentCode
          this.formList[0].form.tubeList[0].checked = 0
          this.$refs.step1Valid.listQuery = preExp[0].form
          this.$refs.step1Valid.tubeList = this.formList[0].form.tubeList
          this.$refs.step1Valid.form = preExp[0].form
          this.$refs.step1Valid.form.generation = res.data.generation
          this.$refs.step1Valid.form.expObjectCode = res.data.expObjectCode
          this.$refs.step1Valid.setQueryParam()
          this.experiment.id = undefined
        })
      } else {
        // 若是新增第一步
        if (this.step === 1 && this.experiment.id == null && this.isPassage == null) {
          getCurrentByType(this.templateType).then(res => {
            if (res.data.content != null) {
              this.formList = res.data.content
              this.experiment.templateId = res.data.id
              this.formList = this.formList.map(item => {
                for (let i = 0; i < item.forms.length; i++) {
                  item.forms[i].form = item.forms[i].form || {}
                }
                return item
              })
            }
          })
        } else {
          getExperiment(this.experiment.id).then(res => {
            this.stepNo = res.data.stepNo
            this.step = res.data.stepNo
            this.experiment = res.data
            this.formList = this.experiment.content
            this.stepIsFinal()
          })
        }
      }
    },
    changeStep (e) {
      this.step = e + 1
      this.stepIsFinal()
    },
    backStep () {
      if (this.formList[this.step - 2].ignore) {
        this.step--
        this.backStep()
      } else {
        this.step--
      }
    },
    prevStep () {
      this.backStep()
      this.isFinal = false
    },
    nextStep () {
      this.experiment.stepNo = this.step
      this.stepNo = (this.stepNo < this.step ? this.step : this.stepNo)
      this.experiment.stepName = this.formList[this.step - 1].name
      this.experiment.content = this.formList
      if (this.step > 1) {
        this.$refs.testProject[0].refreshForm()
        this.validate().then((valid) => {
          if (valid) {
            submit(this.experiment).then(res => {
              if (res.success) {
                this.experiment = res.data
                this.formList = this.experiment.content
                this.forwardStep()
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          }
        })
      } else {
        const testForm = this.$refs.step1Valid
        testForm.$refs.form.validate((valid, msg) => {
          if (!valid) {
            this.$message.error(Object.values(msg)[0][0].message)
          } else {
            submit(this.experiment).then(res => {
              if (res.success) {
                this.experiment = res.data
                this.formList = this.experiment.content
                this.forwardStep()
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          }
        })
      }
    },
    stepIsFinal () {
      let s = true
      if (this.step === this.formList.length) {
        s = true
      } else {
        for (let i = this.step - 1; i < this.formList.length; i++) {
          if (!this.formList[i].ignore) {
            s = false
            break
          } else {
            s = true
          }
        }
      }
      this.isFinal = s
    },
    forwardStep () {
      if (this.formList[this.step].ignore) {
        this.step++
        this.forwardStep()
      } else {
        this.step++
      }
      this.stepIsFinal()
    },
    validate () {
      const that = this
      return new Promise((resolve) => {
        const testForm = this.$refs.testProject
        getValid(0)
        function getValid (i) {
          testForm[i].$refs.form.validate((valid, done, msg) => {
            done()
            if (valid) {
              if (i + 1 >= testForm.length) {
                resolve(valid)
              } else {
                getValid(i + 1)
              }
            } else {
              that.$message.error(Object.values(msg)[0][0].message)
              resolve(valid)
            }
          })
        }
      })
    },
    save () {
      this.experiment.stepNo = this.step
      this.experiment.stepName = this.formList[this.step - 1].name
      this.experiment.content = this.formList
      submit(this.experiment).then(res => {
        if (res.success) {
          this.experiment = res.data
          this.formList = this.experiment.content
          this.$router.push({
            path: '/exp/subculture/index'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }

}
</script>
<style lang="scss">
.step-title{
  white-space: nowrap;
  text-overflow: ellipsis
}
.button-group{
  margin-top: 20px;
  text-align: center;
}
</style>

