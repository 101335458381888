<template>
  <basic-container>
    <div style="display: flex">
      <div ref="pie" class="chart"></div>
      <div ref="bar" class="chart"></div>
    </div>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getNumByStatisticType } from '@/api/market/sample'
import { getPage } from '@/api/market/treatmenthistory'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'market',
      form: {},
      hospitalData: [],
      cancerXAxis: [],
      cancerYAxis: [],
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        menu: false,
        index: true,
        viewBtn: false,
        selection: false,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        column: [
          {
            label: '医院',
            prop: 'hospitalId',
            type: 'select',
            filterable: true,
            rules: [{
              required: true,
              message: '请选择医院'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/hospital/dict'
          },
          {
            label: '科室',
            prop: 'officeId',
            type: 'select',
            filterable: true,
            rules: [{
              required: true,
              message: '请选择科室'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/office/dict`
          },
          {
            label: '医生',
            prop: 'doctorId',
            type: 'select',
            rules: [{
              required: true,
              message: '请选择医生'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/doctor/dict`
          },
          {
            label: '患者',
            prop: 'patientId',
            type: 'select',
            rules: [{
              required: true,
              message: '请选择患者'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/patient/dict`
          },
          {
            label: '手术时间',
            prop: 'operationTime',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            mock: {
              type: 'datetime',
              format: 'yyyy-MM-dd'
            },
            rules: [{
              required: false,
              message: '请输入手术时间'
            }]
          },
          {
            label: '是否复发',
            prop: 'isRecurrence',
            type: 'radio',
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }],
            mock: {
              type: 'dic'
            },
            rules: [{
              required: false,
              message: '请输入是否复发'
            }]
          },
          {
            label: '治疗方案',
            prop: 'treatmentPlan',
            type: 'textarea',
            maxlength: 200,
            showWordLimit: true,
            rules: [{
              required: false,
              message: '请输入治疗方案'
            }]
          },
          {
            label: '疗效评价',
            prop: 'efficacyEvaluation',
            type: 'textarea',
            maxlength: 200,
            showWordLimit: true,
            rules: [{
              required: false,
              message: '请输入疗效评价'
            }]
          },
          {
            label: '最后随访日期',
            prop: 'followUpDate',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            mock: {
              type: 'datetime',
              format: 'yyyy-MM-dd'
            },
            rules: [{
              required: false,
              message: '请输入最后随访日期'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    permissionList () {
    }
  },
  created () {
    this.getData()
  },
  mounted () {
    this.inOrigin()
    this.inBar()
  },
  methods: {
    getData () {
      getNumByStatisticType(1).then(res => {
        if (res.success) {
          this.hospitalData = res.data
          this.inOrigin()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
      getNumByStatisticType(0).then(res => {
        if (res.success) {
          const cancerData = res.data
          for (let i = 0; i < cancerData.length; i++) {
            this.cancerXAxis.push(cancerData[i].name)
            this.cancerYAxis.push(cancerData[i].value)
          }
          this.inBar()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    onLoad (page) {
      getPage(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    // 饼状图
    inOrigin () {
      const myChart = this.$echarts.init(this.$refs.pie)
      // 地域分布---圆形饼状图
      const optionPie = {
        title: {
          text: '医院统计',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: this.hospitalData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // 图标自适应
      const listener = function () {
        myChart.resize()
      }
      window.addEventListener('resize', listener)
      // 图标自适应
      myChart.setOption(optionPie)
    },
    // 柱状图
    inBar () {
      const myChart = this.$echarts.init(this.$refs.bar)
      // 地域分布---圆形饼状图
      const option = {
        title: {
          text: '样本的癌症信息统计',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: this.cancerXAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.cancerYAxis,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      // 图标自适应
      const listener = function () {
        myChart.resize()
      }
      window.addEventListener('resize', listener)
      // 图标自适应
      myChart.setOption(option)
    }
  }
}
</script>
<style lang="scss">
.chart {
  width: 50%;
  height: 400px;
}
</style>
