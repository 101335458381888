<template>
  <basic-container>
    <avue-crud
        :defaults.sync="defaults"
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.exp_consumables_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'
import { remove } from '@/api/exp/consumables'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'exp/consumables',
      form: {},
      search: {},
      defaults: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 6,
        column: [
          {
            label: '耗材编号',
            prop: 'code',
            rules: [{
              required: false,
              message: '请输入耗材编号'
            }],
            disabled: true,
            search: true
          },
          {
            label: '耗材类型',
            prop: 'objectType',
            type: 'select',
            rules: [{
              required: true,
              message: '请选择耗材类型'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/operator_type/2'
          },
          {
            label: '耗材名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入耗材名称'
            }],
            search: true,
            maxlength: 20,
            overHidden: true,
            tip: '最长可输入20个字符'
          },
          {
            label: '耗材品牌',
            prop: 'brand',
            rules: [{
              required: true,
              message: '请输入耗材品牌'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '耗材型号',
            prop: 'model',
            rules: [{
              required: true,
              message: '请输入耗材型号'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '耗材规格',
            prop: 'specifications',
            type: 'select',
            rules: [{
              required: true,
              message: '请输入耗材规格'
            }],
            dicData: [{
              label: '1*6',
              value: 6
            }, {
              label: '2*6',
              value: 12
            }, {
              label: '4*6',
              value: 24
            }, {
              label: '6*6',
              value: 36
            }, {
              label: '6*8',
              value: 48
            }, {
              label: '8*12',
              value: 96
            }, {
              label: '16*24',
              value: 384
            }]
          },
          {
            label: '耗材用途',
            prop: 'purpose',
            rules: [{
              required: true,
              message: '请输入耗材用途'
            }],
            width: 105,
            overHidden: true,
            maxlength: 100,
            tip: '最长可输入100个字符'
          },
          {
            label: '供应商',
            prop: 'supplier',
            rules: [{
              required: false,
              message: '请输入供应商'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '批次',
            prop: 'batchNo',
            rules: [{
              required: false,
              message: '请输入批次'
            }],
            maxlength: 20,
            tip: '最长可输入20个字符'
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            type: 'textarea',
            maxlength: 200,
            tip: '最长可输入200个字符'
          },
          {
            label: '创建人',
            prop: 'creator',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime1',
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.exp_consumables_add,
        viewBtn: this.permission.exp_consumables_view,
        delBtn: this.permission.exp_consumables_delete,
        editBtn: this.permission.exp_consumables_edit
      }
    }
  },
  watch: {
    'form.objectType' (objectType) {
      // 若是细胞培养板、药敏铺板
      if (objectType === '9' || objectType === '24') {
        this.defaults.specifications.type = 'select'
      } else {
        this.defaults.specifications.type = 'input'
      }
    }
  },
  methods: {
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(this.ids).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    }
  }
}
</script>
