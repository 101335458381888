<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item label="冻存编号">
          <el-input
            @keyup.enter.native="handleFilter"
            class="filter-item"
            placeholder="冻存编号"
            v-model="page.freezeCode"></el-input>
        </el-form-item>
        <el-form-item label="样本编号">
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="样本编号"
              v-model="page.sampleCode"></el-input>
        </el-form-item>
        <el-form-item label="冻存对象">
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="冻存对象"
              v-model="page.freezeObjects"></el-input>
        </el-form-item>
        <el-form-item label="冻存人员">
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="冻存人员"
              v-model="page.freezeOperator"></el-input>
        </el-form-item>
        <el-form-item label="复苏人员">
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="复苏人员"
              v-model="page.recoverOperator"></el-input>
        </el-form-item>
        <el-form-item label="放入冰箱时间">
          <el-date-picker
              v-model="page.queryFreezeTime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button @click="handleCreate" icon="el-icon-plus" type="primary">新增</el-button>
      <el-button
              :disabled="this.ids.length === 0"
              @click="batchDelete()"
              icon="el-icon-delete"
              type="danger"
      >
        删除
      </el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        :row-key="getRowKeys"
        @selection-change="handleSelectionChange"
        element-loading-text="拼命加载中"
        border
        fit
        :cell-style="{'text-align':'center'}"
        :header-cell-style="{'text-align':'center'}"
        highlight-current-row>
      <el-table-column
              :reserve-selection="true"
              label="全选"
              type = 'selection'
              width="55">
      </el-table-column>
      <el-table-column label="冻存编号" prop="freezeCode" width="120px">
        <template slot-scope="scope">
          <span>{{scope.row.freezeCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="样本编号" prop="sampleCode" width="120px">
        <template slot-scope="scope">
          <span>{{scope.row.sampleCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="冻存对象" prop="freezeObjects">
        <template slot-scope="scope">
          <span>{{scope.row.freezeObjects}}</span>
        </template>
      </el-table-column>
      <el-table-column label="冻存代次" prop="freezeGeneration">
        <template slot-scope="scope">
          <span>{{scope.row.freezeGeneration}}</span>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="冻存管编号" prop="freezePipeCodes" width="100px">
        <template slot-scope="scope">
          <span>{{scope.row.freezePipeCodes}}</span>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="冻存人员" prop="freezeOperator" width="120px">
        <template slot-scope="scope">
          <span>{{scope.row.freezeOperator}}</span>
        </template>
      </el-table-column>
      <el-table-column label="冻存前照片" prop="freezeImg" width="100px">
        <template slot-scope="scope">
          <span><img v-if="scope.row.freezeImg" :src="imgBaseUrl + scope.row.freezeImg" alt="" style="width: 50px;height: 50px"></span>
        </template>
      </el-table-column>
      <el-table-column label="放入冰箱时间" prop="freezeTime" width="140">
        <template slot-scope="scope">
          <span>{{scope.row.freezeTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="冰箱编号" prop="refrigeratorNo">
        <template slot-scope="scope">
          <span>{{scope.row.refrigeratorNo}}</span>
        </template>
      </el-table-column>

      <el-table-column label="冰箱层位" prop="refrigeratorLocation">
        <template slot-scope="scope">
          <span>{{scope.row.refrigeratorLocation}}</span>
        </template>
      </el-table-column>
      <el-table-column label="移入液氮罐时间" prop="liquidN2Time" width="140">
        <template slot-scope="scope">
          <span>{{scope.row.liquidN2Time}}</span>
        </template>
      </el-table-column>
      <el-table-column label="液氮罐编号" prop="liquidN2No" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.liquidN2No}}</span>
        </template>
      </el-table-column>
      <el-table-column label="液氮罐提号" prop="liquidN2GetNo" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.liquidN2GetNo}}</span>
        </template>
      </el-table-column>
      <el-table-column label="液氮罐层位" prop="liquidN2Location" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.liquidN2Location}}</span>
        </template>
      </el-table-column>
      <el-table-column label="复苏人员" prop="recoverOperator">
        <template slot-scope="scope">
          <span>{{scope.row.recoverOperator}}</span>
        </template>
      </el-table-column>
      <el-table-column label="复苏日期" prop="recoverDate" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.recoverDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="复苏生长时间" prop="recoverTime" width="140">
        <template slot-scope="scope">
          <span>{{scope.row.recoverTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="生长情况照片" prop="recoverImg" width="100">
        <template slot-scope="scope">
          <span><img v-if="scope.row.recoverImg" :src="imgBaseUrl + scope.row.recoverImg" alt="" style="width: 50px;height: 50px"></span>
        </template>
      </el-table-column>
      <el-table-column label="是否成功" prop="isSuccess">
        <template slot-scope="scope">
          <span>{{ typeFormatter(scope.row.isSuccess) }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
            v-if="scope.row.status!='deleted'"
            size="small"
            type="danger"
            @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
        :current-page.sync="page.currentPage"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10,20,30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </basic-container>
</template>

<script>
import { getList, deleteFreeze, remove } from '@/api/exp/freeze'
import { fileSaveUrl } from '@/config/index'

export default {
  data () {
    return {
      total: null,
      list: null,
      ids: [],
      listLoading: true,
      imgBaseUrl: fileSaveUrl,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
        freezeCode: '',
        sampleCode: '',
        freezeObjects: '',
        freezeOperator: '',
        recoverOperator: '',
        queryFreezeTime: ''
      }
    }
  },
  created () {
    // this.onLoad(this.page)
    this.onLoad()
  },
  methods: {
    getRowKeys (row) {
      return row.id
    },
    handleSelectionChange (val) {
      const checked = val.map(item => {
        return item.id
      })
      this.ids = checked
    },
    batchDelete () {
      remove(this.ids.join(',')).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.ids = []
          this.handleFilter()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    onLoad () {
      getList(this.page.currentPage, this.page.pageSize, this.page).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleChangePolicyType (e) {
    },
    handleChangeArea (e) {
      let selectedAreaName = {}
      selectedAreaName = this.areaOptions.find((item) => { // 这里的chargingWorkNameList就是上面遍历的数据源
        return item.dwdmValue === e
        // 筛选出匹配数据，是对应数据的整个对象
      })
      this.form.areaName = selectedAreaName.label
    },
    // 上传成功
    uploadFileSuccess (response, file, fileList) {
      console.log(response)
      if (response.success) {
        this.fileList.push(file)
        this.form.policyUrl = response.data
      } else {
        this.$message.error(response.msg)// 文件上传错误提示
        this.fileList = []
      }
    },
    beforeUpload (file) {
      console.log(file.type, '222')
      if (file.type !== 'doc' && file.type !== 'docx' && file.type === 'pdf') {
        this.fileList = []
        this.$notify({
          title: '上传失败',
          message: '文件格式必须是pdf/word',
          type: 'error',
          duration: 2000
        })
        return false
      }
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleFile (e) {
    },
    getArea () {
    },
    reset () {
      this.page.pageSize = 10
      this.page.currentPage = 1
      this.page.total = 0
      this.page.freezeCode = ''
      this.page.sampleCode = ''
      this.page.freezeObjects = ''
      this.page.freezeOperator = ''
      this.page.recoverOperator = ''
      this.page.queryFreezeTime = ''
      this.handleFilter()
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.$router.push({
        path: '/exp/freeze/step',
        query: { templateType: 3 }
      })
    },
    handleUpdate (id) {
      this.$router.push({
        path: '/exp/freeze/step',
        query: { experimentId: id, templateType: 3 }
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      console.log('22', val)
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('是否删除该冻存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteFreeze(row.id).then(res => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.handleFilter()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保
    },
    typeFormatter: function (status) {
      switch (status) {
        case '0':
          return '否'
        case '1':
          return '是'
      }
    }
  }

}
</script>

<style>
.padding-row {
  margin-bottom: 15px;
}
</style>
