import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/drugtest/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/exp/drugtest/remove',
    method: 'post',
    params: {
      ids
    }
  })
}
export const submit = (row) => {
  return request({
    url: '/exp/drugtest/submit',
    method: 'post',
    data: row
  })
}

export const deleteDrugTest = (id) => {
  return request({
    url: '/exp/drugtest/delete/' + id,
    method: 'post'
  })
}
