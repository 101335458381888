import testTube from '@/components/form/test-tube'
import testTubeDay from '@/components/view-comp/test-tube-day'

import { fileSaveUrl } from '@/config/index'

const option = {
  1: {
    name: '消化液',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/reagent/list?objectType=1`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/reagent/list?objectType=1&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/reagent/list?objectType=1&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入消化液用量'
      }]
    }]
  },
  2: {
    name: '终止液',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/reagent/list?objectType=2`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/reagent/list?objectType=2&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/reagent/list?objectType=2&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入用量'
      }]
    }]
  },
  3: {
    name: '冻存液',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/reagent/list?objectType=3`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/reagent/list?objectType=3&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/reagent/list?objectType=3&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入冻存液用量'
      }]
    }]
  },
  4: {
    name: '缓冲液',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/reagent/list?objectType=4`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/reagent/list?objectType=4&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/reagent/list?objectType=4&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入缓冲液用量'
      }]
    }]
  },
  5: {
    name: '细胞活性检测试剂',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/reagent/list?objectType=5`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/reagent/list?objectType=5&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/reagent/list?objectType=5&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入用量'
      }]
    }]
  },
  6: {
    name: '化合物',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/reagent/list?objectType=6`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/reagent/list?objectType=6&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/reagent/list?objectType=6&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }
      ]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入冻存液用量'
      }
      ]
    }]
  },
  7: {
    name: '培养基',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/reagent/list?objectType=7`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/reagent/list?objectType=7&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/reagent/list?objectType=7&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入用量'
      }]
    }]
  },
  36: {
    name: '药品母液',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/reagent/list?objectType=36`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/reagent/list?objectType=36&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/reagent/list?objectType=36&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入用量'
      }]
    }]
  },
  8: {
    name: '离心管',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=8`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=8&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=8&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '数量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入数量'
      }]
    }]
  },
  9: {
    name: '细胞培养板',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=9`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=9&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=9&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ],
      control: (val, form) => {
        option[26].column[0].params.hole = form.$specifications
        return {
          tube: {
            params: {
              hole: form.$specifications
            }
          }
        }
      }
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '铺板',
      prop: 'tube',
      component: testTube,
      span: 24,
      hole: 24,
      event: {
        input (value) {
          option[26].column[0].params.value = []
          const arr = []
          for (const item of value) {
            if (item) {
              arr.push({ img: '' })
            } else {
              arr.push(null)
            }
          }
          option[26].column[0].params.value = [{ day: 1, data: arr }]
        }
      },
      params: {
        hole: 6
      }
    }]
  },
  10: {
    name: '试剂盒',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=10`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=10&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=10&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '数量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入数量'
      }]
    }]
  },
  11: {
    name: '细胞冻存管',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=11`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=11&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=11&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '耗材编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '数量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入数量'
      }]
    },
    {
      label: '冻存管编号',
      labelWidth: 100,
      prop: 'freezePipeCodes',
      rules: [{
        required: true,
        message: '冻存管编号'
      }],
      tip: '多个冻存管以英文逗号分割'
    }
    ]
  },
  12: {
    name: '吸嘴盒',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=12`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=12&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=12&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '数量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入数量'
      }]
    }]
  },
  13: {
    name: '盒装枪头(TIP头)',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=13`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=13&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=13&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code'
    },
    {
      label: '数量',
      prop: 'amount',
      rules: [{
        required: false,
        message: '请输入数量'
      }]
    }]
  },
  14: {
    name: '细胞培养皿',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=14`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=14&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=14&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '用量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入数量'
      }]
    }]
  },
  41: {
    name: '试剂槽',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=41`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=41&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=41&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '数量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入数量'
      }]
    }]
  },
  15: {
    name: '离心机',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/equipment/list?objectType=15`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/equipment/list?objectType=15&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/equipment/list?objectType=15&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '转速',
      prop: 'speed',
      rules: [{
        required: true,
        message: '请输入转速'
      }]
    },
    {
      label: '时长',
      prop: 'times',
      rules: [{
        required: true,
        message: '请输入时长'
      }]
    },
    {
      label: '其他',
      type: 'textarea',
      prop: 'remarks'
    }]
  },
  16: {
    name: '冰箱',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/equipment/list?objectType=16`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['name'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/equipment/list?objectType=16&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '冰箱',
      prop: 'name',
      type: 'select',
      props: {
        label: 'name',
        value: 'name'
      },
      dicUrl: `exp/equipment/list?objectType=16&brand={{brand}}&model={{model}}`
    },
    {
      label: '冰箱层位',
      prop: 'location',
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      dicUrl: '/sys/dict/code/bx_location'
    },
    {
      label: '冰箱温度',
      prop: 'temperature'
    },
    {
      label: '放入时间',
      prop: 'intoTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '存放时长',
      prop: 'keepTime'
    },
    {
      label: '其他',
      type: 'textarea',
      prop: 'remarks'
    }]
  },
  17: {
    name: '培养箱',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/equipment/list?objectType=17`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/equipment/list?objectType=17&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }],
      labelWidth: 100
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/equipment/list?objectType=17&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }]
    },
    {
      label: '培养箱编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }],
      labelWidth: 100
    },
    {
      label: '培养箱层位',
      prop: 'location',
      type: 'select',
      props: {
        label: 'label',
        value: 'value'
      },
      dicUrl: '/sys/dict/code/pyx_location'
    },
    {
      label: '培养箱温度',
      prop: 'temperature',
      rules: [{
        required: true,
        message: '请输入温度'
      }],
      labelWidth: 100
    },
    {
      label: '放入时间',
      prop: 'intoTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      rules: [{
        required: true,
        message: '请选择放入时间'
      }]
    },
    {
      label: '存放时长',
      prop: 'keepTime',
      rules: [{
        required: true,
        message: '请输入存放时长'
      }],
      labelWidth: 100
    },
    {
      label: '其他',
      type: 'textarea',
      prop: 'remarks'
    }]
  },
  18: {
    name: '液氮罐',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/equipment/list?objectType=18`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/equipment/list?objectType=18&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/equipment/list?objectType=18&brand={{brand}}&model={{model}}`,
      rules: [{
        required: true,
        message: '请选择规格',
        trigger: 'blur'
      }]
    },
    {
      label: '液氮罐编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '液氮罐提号',
      prop: 'nitrogenNo'
    },
    {
      label: '液氮罐层位',
      prop: 'location',
      type: 'select',
      dicUrl: '/sys/dict/code/bx_location'
    },
    {
      label: '放入时间',
      prop: 'intoTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '其他',
      type: 'textarea',
      prop: 'remarks'
    }]
  },
  19: {
    name: '破碎仪',
    submitBtn: false,
    emptyBtn: false,
    labelWidth: 100,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/equipment/list?objectType=19`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/equipment/list?objectType=19&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/equipment/list?objectType=19&brand={{brand}}&model={{model}}`,
      rules: [{
        required: true,
        message: '请选择规格',
        trigger: 'blur'
      }]
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '破碎仪参数',
      prop: 'params',
      rules: [{
        required: true,
        message: '请输入破碎仪参数'
      }],
      labelWidth: 100
    }]
  },
  20: {
    name: '水浴锅',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/equipment/list?objectType=20`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ],
      labelWidth: 100
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/equipment/list?objectType=20&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/equipment/list?objectType=20&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }],
      labelWidth: 100
    },
    {
      label: '编号',
      prop: 'code',
      labelWidth: 90
    },
    {
      label: '水浴锅温度',
      prop: 'temperature',
      rules: [{
        required: true,
        message: '请输入温度'
      }],
      labelWidth: 100
    },
    {
      label: '放入时间',
      prop: 'intoTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      rules: [{
        required: true,
        message: '请选择放入时间'
      }]
    },
    {
      label: '存放时长',
      prop: 'keepTime',
      rules: [{
        required: true,
        message: '请输入存放时长'
      }],
      labelWidth: 100
    },
    {
      label: '其他',
      type: 'textarea',
      prop: 'remarks'
    }]
  },
  21: {
    name: '类器官鉴定类型',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '鉴定类型',
      prop: 'type',
      type: 'radio',
      value: '0',
      rules: [{
        required: true,
        message: '请选择类型'
      }],
      dicData: [{
        label: '基因测序',
        value: '0'
      }, {
        label: '组化鉴定',
        value: '1'
      }],
      control: (val, form) => {
        if (val === '0') {
          return {
            nums: {
              label: '第几代基因测序'
            }
          }
        } else {
          return {
            nums: {
              label: '第几代组化鉴定'
            }
          }
        }
      }
    },
    {
      label: '第几代基因测序',
      prop: 'nums',
      labelWidth: 150,
      maxlength: 200
    }]
  },
  22: {
    name: '类器官鉴定送样',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 150,
      label: '送样人',
      prop: 'sender',
      rules: [{
        required: true,
        message: '请输入送样人'
      }],
      maxlength: 200
    },
    {
      labelWidth: 150,
      label: '送样时间',
      prop: 'sentTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      rules: [{
        required: true,
        message: '请选择放入时间'
      }]
    },
    {
      labelWidth: 150,
      label: '送样前4X镜状态照片',
      prop: 'imgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传送样前4X镜状态照片'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      tip: '只能上传jpg/png文件',
      action: '/common/fileUpload',
      listType: 'picture-img'
    }]
  },
  23: {
    name: '类器官鉴定反馈',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '组化Marker',
      prop: 'marker'
    },
    {
      labelWidth: 120,
      label: '反馈时间',
      prop: 'feedBackTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      rules: [{
        required: true,
        message: '请选择反馈时间'
      }]
    },
    {
      labelWidth: 120,
      label: '反馈文档',
      prop: 'doc',
      type: 'upload',
      loadText: '文件上传中，请稍等',
      row: true,
      span: 8,
      rules: [{
        required: false,
        message: '请上传反馈文档'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      action: '/common/fileUpload',
      limit: 3,
      multiple: true
    },
    {
      labelWidth: 120,
      label: '鉴定是否成功',
      prop: 'isSuccess',
      type: 'radio',
      rules: [{
        required: true,
        message: '请选择鉴定是否成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    }]
  },
  24: {
    name: '药敏铺板',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=24`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=24&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=24&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ],
      control: (val, form) => {
        return {
          tube: {
            params: {
              hole: form.$specifications
            }
          }
        }
      }
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '铺板',
      prop: 'tube',
      component: testTube,
      span: 24,
      params: {
        hole: 96
      }
    }]
  },
  26: {
    name: '药敏生长照片：',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '药敏生长照片',
      prop: 'tubeDay',
      component: testTubeDay,
      span: 24,
      params: {
        hole: 24
      }
    }]
  },
  28: {
    name: '样品处理完照片',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '样品处理感受',
      prop: 'feelings',
      type: 'textarea',
      labelWidth: 120
    },
    {
      label: '样品处理完照片',
      prop: 'imgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传样品处理完照片'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      tip: '只能上传jpg/png文件',
      action: '/common/fileUpload',
      listType: 'picture-img',
      labelWidth: 120
    }]
  },
  29: {
    name: '传代生长照片：',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '传代生长照片',
      prop: 'tubeDay',
      component: testTubeDay,
      span: 24,
      params: {
        hole: 24
      }
    }]
  },
  33: {
    name: '过滤网',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '品牌',
      prop: 'brand',
      type: 'select',
      props: {
        label: 'brand',
        value: 'brand'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=33`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'model',
        value: 'model'
      },
      row: true,
      dicUrl: `exp/consumables/list?objectType=33&brand={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      props: {
        label: 'specifications',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=33&brand={{brand}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }]
    },
    {
      label: '编号',
      prop: 'code'
    },
    {
      label: '数量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入数量'
      }]
    }]
  },
  34: {
    name: '原代培养生长结果',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '生长结果评价',
      prop: 'comments',
      type: 'textarea'
    },
    {
      labelWidth: 120,
      label: '是否有效样本',
      prop: 'isValid',
      type: 'select',
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    },
    {
      labelWidth: 120,
      label: '是否培养成功',
      prop: 'isSuccess',
      type: 'select',
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    }]
  },
  35: {
    name: '样品扩增速度感受：',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 130,
      label: '样品扩增速度感受',
      prop: 'feeling',
      type: 'textarea'
    },
    {
      labelWidth: 120,
      label: '是否培养成功',
      prop: 'isSuccess',
      type: 'select',
      rules: [{
        required: true,
        message: '请选择是否培养成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    },
    {
      labelWidth: 130,
      label: '是否有效样本',
      prop: 'isValid',
      type: 'select',
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }],
      rules: [{
        required: true,
        message: '请选择是否为有效样本'
      }]
    }]
  },
  37: {
    name: '备注',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '操作人',
      prop: 'operator'
    },
    {
      label: '操作时间',
      prop: 'operateTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '创建时间',
      prop: 'createTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '备注',
      prop: 'remarks'
    }]
  },
  38: {
    name: '培养观察结果',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '生长结果评价',
      prop: 'rating',
      type: 'textarea'
    },
    {
      label: '操作时间',
      prop: 'operateTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '创建时间',
      prop: 'createTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '备注',
      prop: 'remarks'
    }]
  },
  39: {
    name: '冻存前照片',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '冻存前照片',
      prop: 'imgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传冻存前照片'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      tip: '只能上传jpg/png文件',
      action: '/common/fileUpload',
      listType: 'picture-img',
      labelWidth: 120
    }]
  },
  40: {
    name: '复苏',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '复苏人',
      prop: 'recoverName'
    },
    {
      labelWidth: 120,
      label: '复苏日期',
      prop: 'recoverDate',
      type: 'datetime',
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd'
    },
    {
      labelWidth: 120,
      label: '复苏生长时间',
      prop: 'recoverTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      labelWidth: 120,
      label: '是否成功',
      prop: 'isSuccess',
      type: 'select',
      rules: [{
        required: true,
        message: '请选择是否成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    }, {
      label: '生长情况照片',
      prop: 'imgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传冻存前照片'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      tip: '只能上传jpg/png文件',
      action: '/common/fileUpload',
      listType: 'picture-img',
      labelWidth: 120
    }]
  },
  42: {
    name: '药敏结果报告',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '药敏报告文件',
      prop: 'doc',
      type: 'upload',
      loadText: '文件上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传反馈文档'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      action: '/common/fileUpload',
      limit: 3,
      multiple: true
    },
    {
      labelWidth: 120,
      label: '药敏结果时间',
      prop: 'drugResultTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      labelWidth: 120,
      label: '冻存前后结果是否一致',
      prop: 'isFit',
      type: 'select',
      rules: [{
        required: true,
        message: '请选择是否成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    }]
  }
}
export default option
