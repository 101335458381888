import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/experiment/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/exp/experiment/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/exp/experiment/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/exp/experiment/submit',
    method: 'post',
    data: row
  })
}

export const list = (params) => {
  return request({
    url: '/exp/experiment/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const tubeList = (params) => {
  return request({
    url: '/exp/experiment/listTube',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const generationDic = (params) => {
  return request({
    url: '/exp/experiment/generationDic',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const expObjectCodeDic = (params) => {
  return request({
    url: '/exp/experiment/expObjectCodeDic',
    method: 'get',
    params: {
      ...params
    }
  })
}

