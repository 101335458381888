<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane v-for="(day,index) in value" :key="day" :name="index">
        <span slot="label"><i class="el-icon-date"></i>{{' Day ' + day.day}}</span>
        <div :class="'tubeStand s' + col">
          <div class="tubehead">
            <span v-for="(item, index) in getName(col)" :key="item">{{ index + 1 }}</span>
          </div>
          <div style="display: flex">
            <div class="tuberow">
              <span v-for="item in getName(row,'letter')" :key="item">{{item}}</span>
            </div>
            <div class="tubeList">
              <div :class="day.data[i]?'tubeHole active':'tubeHole'" v-for="(item, i) in tubeList" :key="i" @click="handleTube(day.data[i],index)">
                  <i class="el-icon-picture-outline"></i>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
        :title="`Day${activeDay} - ${activeHole.name}`"
        :visible.sync="dialogVisible"
        width="30%"
        center>
      <el-image
          v-if="dialogView.img?.length"
          style="width: 200px; height: 200px; margin: 0 auto; display: block;background: #F2F6FC;"
          fit="scale-down"
          :src="'http://localhost:6626/admin/common/viewImg/' + dialogView.img"
          :preview-src-list="['http://localhost:6626/admin/common/viewImg/' + dialogView.img]">
      </el-image>

          <span slot="footer" class="dialog-footer">
               <el-upload
                   class="upload-demo"
                   :action="'http://localhost:6626/admin/common/fileUpload'"
                   :on-preview="handlePreview"
                   :on-remove="handleRemove"
                   :before-remove="beforeRemove"
                   :limit="1"
                   :on-success="upLoadImg"
                   :on-exceed="handleExceed">
            <el-button size="small" v-if="dialogView.img?.length" type="primary">重新上传</el-button>
            <el-button size="small" v-else type="primary">上传图片</el-button>
          </el-upload>
          </span>

    </el-dialog>
  </div>
</template>

<script>

import { fileSaveUrl } from '@/config/index'
const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const grid = {
  g6: [1, 6],
  g12: [2, 6],
  g24: [4, 6],
  g36: [6, 6],
  g48: [6, 8],
  g96: [8, 12],
  g384: [16, 24]
}
export default {
  name: 'TestTubeDay',
  props: {
    hole: {
      type: [Number, String],
      default: 24
    },
    value: {
      type: Array
    }
  },
  data () {
    return {
      fileSaveUrl,
      dialogView: {},
      activeDay: 1,
      activeHole: 0,
      row: 0,
      col: 0,
      activeName: 0,
      dialogVisible: false,
      tubeList: [],
      days: new Array(7)
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {
      const arr = []
      let row, col
      const hole = parseInt(this.hole)
      if (grid[`g${hole}`]) {
        row = grid[`g${hole}`][0]
        col = grid[`g${hole}`][1]
      } else {
        row = 4
        col = 6
      }
      this.row = row
      this.col = col
      const count = row * col
      for (let i = 0; i < count; i++) {
        arr.push({
          name: `${letters[Math.ceil((i + 1) / col) - 1]}${i % col + 1}`,
          index: i,
          checked: false
        })
      }
      this.tubeList = arr
    },
    getName (number, key) {
      if (key === 'letter') {
        const arr = []
        for (let i = 0; i < number; i++) {
          arr.push(letters[i])
        }
        return arr
      }
      return new Array(number)
    },
    handleClick (e) {
      console.log('-->', e.name)
      this.activeDay = this.value[e.name].day
    },
    upLoadImg (response, file, fileList) {
      console.log(response, file, fileList)
      this.dialogView.img = response.data.url
      this.$emit('change', this.value)
    },
    handleTube (item, index) {
      if (item) {
        this.dialogView = item
        this.activeHole = this.tubeList[index]
        this.dialogVisible = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tubeHole-tip{
  width: 100%;
  height: 100%;
}
.tubeStand{
  padding: 20px 20px 10px 20px;
  width: 552px;
  border-radius: 30px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
}
.tubeList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
$width-1x: 80px;
$margin-hole: $width-1x/10;
$width-real: $width-1x - $margin-hole;
.tubeHole{
  border-radius: 50%;
  border: 1px solid #DCDFE6;
  transition: all .2s;
  box-sizing: border-box;
  i{
    display: none;
  }
}
.s6 .tubeHole{
  line-height:$width-real ;
  font-size:$width-real / 2 ;
  width: $width-real;
  height: $width-real;
  margin: 0 $margin-hole/2 $margin-hole $margin-hole/2;
}
.s8 .tubeHole{
  width: $width-real * 0.75;
  height: $width-real * 0.75;
  margin: 0 $margin-hole/2 * 0.75 $margin-hole * 0.75 $margin-hole/2 * 0.75;
}
.s12 .tubeHole{
  width: $width-real * 0.5;
  height: $width-real * 0.5;
  margin: 0 $margin-hole/2 * 0.5 $margin-hole * 0.5 $margin-hole/2 * 0.5;
}
.s24 .tubeHole{
  width: $width-real * 0.25;
  height: $width-real * 0.25;
  line-height: $width-real * 0.25;
  font-size: 12px;
  margin: 0 $margin-hole/2 * 0.25 $margin-hole * 0.25 $margin-hole/2 * 0.25;
}
.tuberow{
  flex-shrink:0;
  width: 30px;
  span{
    box-sizing: border-box;
    color: #C0C4CC;
    display: block;
    text-align: center;
  }
}
.s6 .tuberow{

  span{
    height: $width-1x;
    line-height: $width-1x;
  }
}
.s8 .tuberow{

  span{
    height: $width-1x * 0.75;
    line-height: $width-1x * 0.75;
  }
}
.s12 .tuberow{

  span{
    height: $width-1x * 0.5;
    line-height: $width-1x * 0.5;
  }
}
.s24 .tuberow{

  span{
    height: $width-1x * 0.25;
    line-height: $width-1x * 0.25;
  }
}
.tubehead{
  display: flex;
  height: 30px;
  line-height: 30px;
  color: #C0C4CC;
  justify-content: space-between;
  padding-left: 30px;
  span{
    display: block;
    width: 60px;
    text-align: center;
  }
}
.s24 .tubehead{
  span{
    width: $width-1x * 0.25;
  }
}
.s12 .tubehead{
  span{
    width: $width-1x * 0.5;
  }
}
.s6 .tubehead{
  span{
    width: $width-1x;
  }
}
.s8 .tubehead{
  span{
    width: $width-1x * 0.75;
  }
}
.tubeHole.active{
  background: #409EFF;
  border: 1px solid #409EFF;
  color: #fff;
  text-align: center;
  cursor: pointer;
  i{
    display: inline-block;
  }
}
</style>
