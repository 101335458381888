<template>
  <div class="comp2">
    动态组件子组件2
  </div>
</template>

<script>
export default {
  name: 'Comp2'
}
</script>
