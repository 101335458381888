<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.setting_experimentdatatemplate_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'setting/operator',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        labelWidth: 100,
        column: [
          // {
          //   label: '样品处理完照片',
          //   prop: 'imgUrl',
          //   type: 'upload',
          //   loadText: '图片上传中，请稍等',
          //   span: 24,
          //   propsHttp: {
          //     res: 'data'
          //   },
          //   tip: '只能上传jpg/png文件',
          //   action: '/common/fileUpload'
          // },
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }],
            hide: true,
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false
          },
          {
            label: '操作项类型',
            prop: 'type',
            type: 'select',
            cascader: ['objectType'],
            cascaderIndex: 1,
            rules: [{
              required: true,
              message: '请输入对象类型-代码'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/exp_object_type',
            width: 100
          },
          {
            label: '对象类型',
            prop: 'objectType',
            type: 'select',
            rules: [{
              required: true,
              message: '请输入对象类型-代码'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/operator_type/{{type}}',
            width: 80
          },
          {
            label: '配置方式',
            prop: 'configType',
            type: 'radio',
            hide: true,
            value: '0',
            rules: [{
              required: true,
              message: '请输入对象ID'
            }],
            dicData: [{
              label: '通用配置',
              value: '0'
            }, {
              label: '个性配置',
              value: '1'
            }],
            control: (val, form) => {
              console.log(val)
              if (val === '1') {
                console.log('该显示了')
                return {
                  objectId: {
                    display: true
                  }
                }
              } else {
                console.log('该隐藏了')
                return {
                  objectId: {
                    display: false
                  }
                }
              }
            }
          },
          {
            label: '对象ID',
            prop: 'objectId',
            rules: [{
              required: true,
              message: '请输入对象ID'
            }],
            hide: true,
            display: false
          },
          {
            label: '名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入名称'
            }],
            width: 120
          },
          {
            label: '参数',
            prop: 'contentData',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '参数1',
            prop: 'content2',
            type: 'dynamic',
            span: 24,
            hide: true,
            children: {
              align: 'center',
              headerAlign: 'center',
              rowAdd: (done) => {
                done()
              },
              rowDel: (row, done) => {
                done()
              },
              column: [{
                label: '字段属性',
                prop: 'prop'
              },
              {
                label: '名称',
                prop: 'name'
              },
              {
                label: '类型',
                prop: 'type',
                type: 'select',
                dicData: [
                  {
                    label: '文本',
                    value: 1
                  }, {
                    label: '下拉',
                    value: 2
                  }, {
                    label: '文本域',
                    value: 3
                  }, {
                    label: '细胞板',
                    value: 4
                  }, {
                    label: '时间',
                    value: 5
                  }, {
                    label: '单选',
                    value: 6
                  }, {
                    label: '图片',
                    value: 7
                  }, {
                    label: '文件',
                    value: 8
                  }
                ]
              }]
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.setting_experimentdatatemplate_add,
        viewBtn: this.permission.setting_experimentdatatemplate_view,
        delBtn: this.permission.setting_experimentdatatemplate_delete,
        editBtn: this.permission.setting_experimentdatatemplate_edit
      }
    }
  },
  mounted () {
    // 放在数据加载完后执行
    this.$refs.crud.dicInit('cascader')
  },
  methods: {
  }
}
</script>
